"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColorScheme = void 0;
const react_native_1 = require("react-native");
const useConfig_1 = require("./useConfig");
function useColorScheme() {
    try {
        const [mode] = (0, useConfig_1.useConfig)('theme.mode');
        const colorScheme = (0, react_native_1.useColorScheme)();
        if (mode === 'light' || mode === 'dark') {
            return mode;
        }
        if (!colorScheme) {
            return 'light';
        }
        return colorScheme;
    }
    catch (error) {
        return 'light';
    }
}
exports.useColorScheme = useColorScheme;
